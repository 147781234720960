
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { IStore } from "../types/IStore";


const nullStore: IStore = {
  storeCode: '',
  storeName: undefined,
  inScope: false
}

const useCurrentStore = (): IStore => {
  const context = useContext(AppContext)
  const [store, setStore] = useState(context && context.store!== undefined ? context.store : nullStore)

  useEffect(() => {
    if (context && context.store !== undefined) {
      setStore(context?.store)
    }
  }, [context?.store, store])

  return store
}

export default useCurrentStore;
