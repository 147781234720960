import { Body1 } from '@jsluna/typography'
import { IProductInformation } from '../../../types/ITrackingOverview'

const ProductInformation = ({productSKU, productBarcode, number}: IProductInformation) => {
  return (
    <Body1>
      <>
        <span
          className="a-incident-sku ln-u-font-weight-bold"
          id="a-incident-sku"
        >
          {productSKU}
        </span>

        {productBarcode === '' ? '' : ` / `}

        <span
          className="a-incident-productBarcode ln-u-color-grey"
          id="a-incident-productBarcode"
        >
          {productBarcode}
        </span>

      </>

      <div
        className="a-incident-incidentNumber ln-u-soft-bottom ln-u-color-grey"
        id="a-incident-number"
      >
        {number}
      </div>
    </Body1>
  )
}

export default ProductInformation
