import { useEffect, useRef, useState } from 'react'
import { OutlinedButton } from '@jsluna/button'
import { ProgressIndicator, ProgressBar } from '@jsluna/progress'
import { FormGroup } from '@jsluna/form'

import { useComponentContext } from '../../ComponentContext'
import { removeFromArray } from '../../../../../utils/array'
import ImagePreview from './ImagePreview'
import { FileUpload } from '../../../../../api/loggingApi'
import { useApiClient } from '../../../../../context/AppContext'

function UploadComponent({
  label,
  id,
  multiple,
  text,
  error: validationError,
  answer,
}: any) {
  const { handleUpdate, enableNext, disableNext } = useComponentContext()
const apiClient= useApiClient()
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState((answer && JSON.parse(answer)) || [])
  const [error, setError] = useState(null)
  const fileInputRef: any = useRef(null)

  const handleUpload = async (e: any) => {
    e.preventDefault()

    const files = Array.from(e.target.files)

    disableNext()
    setLoading(true)

    const response: any = await FileUpload(apiClient,files)

    if (!response.error) {
      setError(null)

      setImages((prevImages: any) => [
        ...prevImages,
        ...files.map((file: any, key) => ({
          name: response.imageNames[key],
          displayName: file.name,
          previewUrl: URL.createObjectURL(file),
        })),
      ])
    } else {
      setError(response.message ?? 'An error has occurred')
    }

    enableNext()
    setLoading(false)
    if (fileInputRef.current) {
    fileInputRef.current.value = null
    }
  }

  const removeImage = async (image: any) => {
    setImages((prevState: any) => removeFromArray(prevState, image))
  }

  useEffect(() => handleUpdate({ id, value: '' }), [])
  useEffect(() => setError(validationError), [validationError])

  useEffect(() => {
    handleUpdate({
      id,
      value: JSON.stringify(images),
    })
  }, [images])

  return (
    <>
      <div>
        <FormGroup name="a-files-upload-button" error={error} label={label}>
          <OutlinedButton
            element="label"
            className="fileinputlabel"
            htmlFor="a-files-upload-button"
            disabled={loading}
            fullWidth
          >
            <input
              type="file"
              id="a-files-upload-button"
              name={label}
              multiple={multiple}
              onChange={handleUpload}
              disabled={loading}
              className="fileinput"
              ref={fileInputRef}
            />
            {text}
          </OutlinedButton>
        </FormGroup>
      </div>
      <div id="a-files-upload-message">
        {loading && (
          <ProgressIndicator loading preventFocus>
            <ProgressBar
              className="ln-u-push-bottom-lg"
              title="Regular sized progress indicator demo"
            />
          </ProgressIndicator>
        )}
        {!loading && <ImagePreview removeImage={removeImage} images={images} />}
      </div>
    </>
  )
}

export default UploadComponent
