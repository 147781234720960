import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { OutlinedButton } from '@jsluna/button'
import { useState } from 'react'
import BarcodeScanner from '@jsainsburyplc/retail-barcode-scanner'
import { useComponentContext } from '../../ComponentContext'

function SubmitBarcode({ id, label, barcodeFormat, checkDigit }: any) {
  const { handleNext } = useComponentContext()
  const [isScanning, setIsScanning] = useState(false)

  const onScan = ({ code }: any) => {
    setIsScanning(false)
    handleNext({ id, value: code })
  }

  if (isScanning)
    return (
      <BarcodeScanner
        formats={barcodeFormat}
        onCancel={() => setIsScanning(false)}
        onScan={onScan}
        checkDigit={checkDigit}
      />
    )

  return (
    <div className="ln-c-form-group">
      <OutlinedButton
        id="a-barcodeScanner-button"
        fullWidth
        onClick={() => setIsScanning(true)}
      >
        {label && label.length > 0 ? label : 'Scan barcode'}
      </OutlinedButton>
    </div>
  )
}

export default SubmitBarcode
