import { Body1, Display2 } from '@jsluna/typography'

function TextComponent({ label, value }: any) {
  return (
    <div id="a-logging-text">
      {label && (
        <Display2 id="a-logging-text-title" element="h6" label={label}>
          {label}
        </Display2>
      )}
      <Body1 id="a-logging-text-body" element="p">
        {value}
      </Body1>
    </div>
  )
}

export default TextComponent
