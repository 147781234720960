import { useState } from 'react'
import { SelectField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'

function DropdownComponent({ label, options, id, error, answer }: any) {
  const { handleUpdate } = useComponentContext()
  const [descriptionText, setDescriptionText] = useState('')
  const handleChange = (e: any) => {
    setDescriptionText(
      options.find((el: any) => el.value === e.target.value)?.description,
    )
    handleUpdate({ id, value: e.target.value })
  }

  return (
    <div id="a-logging-dropdown" tabIndex={-1}>
      <SelectField
        name={id}
        label={label}
        className="a-logging-dropdown"
        options={options}
        error={error}
        onChange={handleChange}
        placeholder={'Please Select'}
        value={answer || ''}
      />
      <p id="a-option-description">{descriptionText}</p>
    </div>
  )
}

export default DropdownComponent
