import TitleComponent from './Components/TitleComponent'
import CardComponent from './Components/CardComponent'
import NextComponent from './Components/NextComponent'
import DropdownComponent from './Components/DropdownComponent'
import TextComponent from './Components/TextComponent'
import HyperlinkComponent from './Components/HyperlinkComponent'
import LinkButtonComponent from './Components/LinkButtonComponent'
import InputComponent from './Components/InputComponent'
import CancelComponent from './Components/CancelComponent'
import IconComponent from './Components/IconComponent'
import MultiLineInputComponent from './Components/MultiLineInputComponent'
import SubmitBarcodeComponent from './Components/SubmitBarcodeComponent'
import UploadComponent from './Components/UploadComponent'
import BackComponent from './Components/BackComponent'
import RadioButtonComponent from './Components/RadioButtonComponent'
import ProductInfoComponent from './Components/ProductInfoComponent'

const components = {
  title: TitleComponent,
  card: CardComponent,
  next: NextComponent,
  dropdown: DropdownComponent,
  text: TextComponent,
  hyperlink: HyperlinkComponent,
  link_button: LinkButtonComponent,
  input: InputComponent,
  cancel: CancelComponent,
  back: BackComponent,
  icon: IconComponent,
  multi_line_input: MultiLineInputComponent,
  submit_barcode: SubmitBarcodeComponent,
  upload: UploadComponent,
  radiobutton: RadioButtonComponent,
  productInfo: ProductInfoComponent,
}

// This is a function so it can be mocked.
export default function getAvailableComponents() {
  return components
}
