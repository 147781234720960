import { useApiClient } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import { useCallbackRef, useResourceLoader } from '.'
import { IDirectories } from '../types/IAllEntriesDirectory'

import directoryApi from '../api/directoryApi'
import { ILoadAllEntriesDirectory, ILoadEntryDirectory } from '../types/ILoadDirectory'
import { FetchAllEntriesDirectory, FetchEntryDirectory } from '../types/FetchDirectory'
import { IDirectory } from '../types/IEntryDirectory'


export const useAllEntriesDirectoryLoader = (): [
  IResourceLoader<IDirectories[]>,
  ILoadAllEntriesDirectory
] => {
  const apiClient= useApiClient()

  const resource = useResourceLoader<IDirectories[]>('Unable to load directory list')

  const loader = useCallbackRef<FetchAllEntriesDirectory>((storeCode:string) =>
    resource.load(async () => directoryApi.GetDirectories(apiClient, storeCode))
  )

  return [resource, loader]
}


export const useEntryDirectoryLoader = (): [
  IResourceLoader<IDirectory>,
  ILoadEntryDirectory
] => {
  const apiClient= useApiClient()

  const resource = useResourceLoader<IDirectory>('Unable to load directory')

  const loader = useCallbackRef<FetchEntryDirectory>((identifier: string) =>
    resource.load(async () => directoryApi.GetDirectory(apiClient, identifier))
  )

  return [resource, loader]
}

