import { RadioButtonField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'

function RadioButtonComponent({
  label,
  options,
  id,
  error,
  answer,
  listType,
  outlined,
  fullWidth,
}: any) {
  const { handleUpdate } = useComponentContext()
  const handleChange = (e: any) => {
    handleUpdate({ id, value: e.target.value })
  }

  return (
    <div id="a-logging-radiobutton">
      <RadioButtonField
        label={label}
        name={id}
        options={options}
        className="a-logging-radiobutton"
        error={error}
        onChange={handleChange}
        value={answer || ''}
        listType={listType}
        outlined={outlined}
        fullWidth={fullWidth}
      />
    </div>
  )
}

export default RadioButtonComponent
