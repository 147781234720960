import { Display3 } from '@jsluna/typography'
import { ITitle } from '../../../types/ITrackingOverview'

const Title = ({ text }: ITitle) => {
  return (
    <Display3 element="h2" className="ln-u-color-grey-dark ln-u-flush-bottom">
      <span id="a-incident-productDescription">{text}</span>
    </Display3>
  )
}

export default Title
