import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'

const Loading = (message: any) => (
  <ProgressIndicator
    className="ln-u-justify-content-center"
    loading
    preventFocus
  >
    <p className="loading-mesage">
      <ProgressSpinner size="medium" className="ln-u-push-right-sm" />
      {message.message}
    </p>
  </ProgressIndicator>
)

export default Loading
