import { Card } from '@jsluna/card'
import {
  Title,
  ProductInformation,
  IssueType,
  IncidentDescription,
  IncidentState,
  ReportedUpdatedOn
} from '../Components'
import { IIncident } from '../../../types/ITracking'
import { GridItem } from '@jsluna/grid'
import { Link } from 'react-router-dom'

const TrackingCard = ({incident} : IIncident) => {
  return (
    <GridItem key={incident.number} size="sm">
      <Link to={`/issue/${incident.identifier}`} className="a-no-link-style">
        <Card
          className="a-incident-card ln-u-border"
          id={`incident-card-${incident.number}`}
        >
          <Title text={incident.metadata.productDescription} />
          <ProductInformation
            productSKU={incident.metadata.productSKU}
            productBarcode={incident.metadata.productBarcode}
            number={incident.number}
          />
          <IssueType issueType={incident.metadata.issueType} />
          <IncidentDescription
            shortDescription={incident.shortDescription}
            description={incident.description}
          />
          <IncidentState state={incident.state} />
          <ReportedUpdatedOn
            className="a-incident-updatedOn"
            incident={incident}
          />
        </Card>
      </Link>
    </GridItem>
  )
}

export default TrackingCard
