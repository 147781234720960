import React from 'react'
import { Card } from '@jsluna/card'
import { Modal, ModalHeading } from '@jsluna/modal'
import { Body1, Caption } from '@jsluna/typography'
import getRelativeDate from '../../../../utils/relativeDate'

function SwitchCase(history: any) {
  const [isOpen, setIsOpen] = React.useState(false)
  const issueHistory = history.history

  switch (issueHistory.elementType) {
    case 'comment':
      return (
        <>
          <Body1 className="a-comment-value" id="a-comment-value">
            {issueHistory.value}
          </Body1>
          <br />
          <Caption
            className="a-comment-createdOnBy ln-u-color-grey"
            id="a-comment-createdOnBy"
          >
            Updated on&nbsp;{getRelativeDate(issueHistory.createdOn)} by&nbsp;
            {issueHistory.createdBy?.displayName}
          </Caption>
        </>
      )
    case 'attachment':
      return (
        <>
          <Body1 className="a-attachment-value" id="a-attachment-value">
            <div
              role="button"
              tabIndex={0}
              onClick={() => setIsOpen(true)}
              onKeyDown={() => setIsOpen(true)}
            >
              <img
                src={issueHistory.blobThumbnailLink || issueHistory.blobLink}
                alt=""
                aria-label="image-thumbnail"
                width="128"
                height="128"
              />
            </div>
            <Modal
              medium
              handleClose={() => setIsOpen(false)}
              open={isOpen}
              headingId="info-modal"
            >
              <ModalHeading element="h5">{issueHistory.value}</ModalHeading>
              <img src={issueHistory.blobLink} alt="" />
            </Modal>
            <br />
            {issueHistory.value}
          </Body1>
          <br />
          <Caption
            className="a-attachment-createdOnBy ln-u-color-grey"
            id="a-attachment-createdOnBy"
          >
            Uploaded on&nbsp;{getRelativeDate(issueHistory.createdOn)}
          </Caption>
        </>
      )
    default:
      return null
  }
}

function HistoryElement({ history }: any) {
  return (
    <Card
      className="a-issueHistory-element ln-u-border-left ln-u-border-right ln-u-border-bottom"
      id={`a-issue-history-element-${history.id}`}
    >
      <SwitchCase history={history} />
    </Card>
  )
}

export default HistoryElement
