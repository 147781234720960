import { ConfigurationType } from './types/ConfigurationType'

const config: ConfigurationType = {
  scope: process.env.REACT_APP_MSAL_CLIENT_SCOPE ?? "{MSAL_SCOPE}",
  tutorialServiceScope: process.env.REACT_APP_MSAL_CLIENT_SCOPE_TUTORIALSERVICE ?? "{TUTORIALSERVICE_MSAL_SCOPE}",
  feedbackServiceScope: process.env.REACT_APP_MSAL_CLIENT_SCOPE_FEEDBACKSERVICE ?? "{FEEDBACKSERVICE_MSAL_SCOPE}",
  clientAuthority: process.env.REACT_APP_MSAL_AUTHORITY ?? "{MSAL_AUTHORITY}",
  clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "{MSAL_CLIENT_ID}",
  apiBaseUrl: process.env.REACT_APP_BASEURL ?? "{API_BASE_URL}"
}

export { config }
