import React, { useContext } from 'react'

const ComponentContext = React.createContext<any>({})

export class ComponentContextData {
  answers: any
  handleNext: any
  handleUpdate: any
  handleCancel: any
  handleBack: any
  enableNext: any
  disableNext: any
  isNextDisabled: any
  constructor(
    availableComponents: any,
    answers: any,
    handleNext: any,
    handleUpdate: any,
    handleCancel: any,
    handleBack: any,
    enableNext: any,
    disableNext: any,
    isNextDisabled: any,
  ) {
    this.availableComponents = availableComponents
    this.answers = answers
    this.handleNext = handleNext
    this.handleUpdate = handleUpdate
    this.handleCancel = handleCancel
    this.handleBack = handleBack
    this.enableNext = enableNext
    this.disableNext = disableNext
    this.isNextDisabled = isNextDisabled
  }
  availableComponents: any
}

export function ComponentContextProvider({
  children,
  availableComponents,
  answers,
  handleNext,
  handleUpdate,
  handleCancel,
  handleBack,
  enableNext,
  disableNext,
  isNextDisabled,
}: any) {
  return (
    <ComponentContext.Provider
      value={
        new ComponentContextData(
          availableComponents,
          answers,
          handleNext,
          handleUpdate,
          handleCancel,
          handleBack,
          enableNext,
          disableNext,
          isNextDisabled,
        )
      }
    >
      {children}
    </ComponentContext.Provider>
  )
}

export const useComponentContext = () => useContext(ComponentContext)
