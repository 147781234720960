import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import TrackingList from '../components/Tracking/TrackingList/TrackingList'

const TrackingPage = () => {

    const pageNote = () => {
      return (
        <Card className="ln-u-bg-color-black ln-u-color-light">
            <Container>
              Supply chain issues (such as availability) are not covered .
              Progress updates are shown in the details of the problem. You will
              then see the resolution in the log once it has been resolved. All
              previously closed queries are visible for 14 days.
            </Container>
        </Card>
      )
    }

    return (
      <>
        <Container size="xs" className="a-page-push" id="a-dashboard-card">
          <TrackingList />
        </Container>

        {pageNote()}
      </>
    )
  }

export default TrackingPage
