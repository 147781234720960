import { Display1 } from '@jsluna/typography'

function HyperlinkComponent({ value, link, id }: any) {
  return (
    <span className="a-hyperlink">
      <a id={id} href={link}>
        <Display1>{value}</Display1>
      </a>
    </span>
  )
}

export default HyperlinkComponent
