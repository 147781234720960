import { useEffect } from "react"
import { Container } from '@jsluna/react';
import Loading from "../components/Loading"
import SwitchboardButton from "../components/Switchboard/SwitchboardButton";
import Error from "../components/Error";
import { IDirectories } from "../types/IAllEntriesDirectory";
import { useAllEntriesDirectoryLoader } from "../hooks/useDirectoryLoader";
import { useCurrentStore } from "../hooks";
import { useNavigate } from "react-router";


const SwitchBoardPage = () => {
  const navigate = useNavigate()
  const currentStore = useCurrentStore()
  let entries: IDirectories[] = []

  const [directoryResource, loadDirectory] = useAllEntriesDirectoryLoader()

  useEffect(() => {
    loadDirectory.current(currentStore.storeCode)
  }, [loadDirectory])


  if (directoryResource.hasError)
    return (
      <Error
        message="An error has occurred. "
        tryAgain={() => navigate('/')}
      />
    )

  if (!directoryResource.hasLoaded) return <Loading message="Loading directory" />

  if (directoryResource.hasLoaded && directoryResource.data) {
    entries = directoryResource.data
  }

  const splitDirectoryEntries = (entries: any[]) =>
    entries &&
    entries.reduce(
      ({ main, footer }, c) => {
        if (c.id === 'unable-to-trade') return { main, footer: [...footer, c] }
        return { main: [...main, c], footer }
      },
      { main: [], footer: [] },
    )

  const { main: mainEntries, footer: footerEntries } =
    splitDirectoryEntries(entries) || {}

  return (
    <>
      <Container
        fluid
        size="xs"
        className="a-switchboard-main ln-u-bg-color-brand-1 ln-u-color-white ln-u-padding-bottom*2"
      >
        <Container
          soft
          size="xs"
          className="ln-u-padding-top*3"
          id="a-switchboard-main"
        >
          <h1>Hello</h1>
          <h3>How can we help you today?</h3>
          <p className="ln-u-flush">You can help me with ...</p>
          {mainEntries &&
            mainEntries.map((entry: any) => (
              <SwitchboardButton
                className={`a-switchboard-button-${entry.name}`}
                id={`a-switchboard-button-${entry.name}`}
                key={entry.id}
                entry={entry}
              />
            ))}
        </Container>
      </Container>
      <Container
        className="a-switchboard-footer ln-u-padding-top*2 ln-u-padding-bottom*2"
        id="a-switchboard-footer"
        soft
        size="xs"
      >
        {footerEntries &&
          footerEntries.map((entry: any) => (
            <SwitchboardButton
              key={entry.id}
              entry={entry}
              variant="outlined"
              color="orange"
            />
          ))}
      </Container>
    </>
  )
}

export default SwitchBoardPage