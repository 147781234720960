import { List, ListItem } from '@jsluna/list'
import { TextButton } from '@jsluna/button'
import { FC } from 'react'
import { IDetails } from '../../../types/IStoreDetails'
import { IStore } from '../../../types/IStore'

interface StoreDisplayProps{
  storeDetail : IDetails
   index:number
   currentStore: IStore
   changeStore:(store: any) => void
}

const StoreDisplay :FC<StoreDisplayProps>= ({storeDetail, index, currentStore,changeStore} ) => {

  const selectedIcon =
    !!currentStore && currentStore.storeCode === storeDetail.code ? <span>✓</span> : ''

    const selectNewStore = (storeSelected:any)  => {
      changeStore(storeSelected)
      
    }

  return (
    <List
      key={index}
      type="bare"
      spaced
      className="ln-u-display-flex@md c-preview__list"
    >
      <ListItem>
        <TextButton
          id={`store-select-${storeDetail.code}`}
          className="text__button"
          color="dark"
          fullWidth
          onClick={() => selectNewStore(storeDetail)}
        >
          {selectedIcon}
          <span className="displayName">
            {storeDetail.name} ({storeDetail.code})
          </span>
        </TextButton>
      </ListItem>
    </List>
  )
}

export default StoreDisplay
