import { IIncidentDescription } from '../../../types/ITrackingOverview'

const IncidentDescription = ({
  shortDescription,
  description
}: IIncidentDescription) => {
  return (
    <>
      <div
        className="a-incident-shortDescription"
        id="a-incident-shortDescription"
      >
        {shortDescription}
      </div>
      <div
        className="a-incident-description ln-u-soft-bottom"
        id="a-incident-description"
      >
        {description}
      </div>
    </>
  )
}

export default IncidentDescription
