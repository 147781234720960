import { ApiClient } from './ApiClient'
import { ITracking } from '../types/ITracking'
import { IIncident } from '../types/IIncident'
import webApi from './webApi'


const GetStoreIncidents = async (
  apiClient: ApiClient,
  storeCode: string,
  page: number
): Promise<ITracking> => {
  const response = await webApi.get<ITracking>(apiClient, {
    url: `/api/tracking`,
    params: { storeCode: storeCode, pageNumber: page }
  })

  return response
}

const GetIncident = async (
  apiClient: ApiClient,
  incidentIdentifier: string
): Promise<IIncident> => {
  const response = await webApi.get<IIncident>(apiClient, {
      url: `/api/tracking/${incidentIdentifier}`,
      params: { incidentIdentifier: incidentIdentifier }
  })
  console.log(response)
  return response
}

const trackingApi = {
  GetStoreIncidents,
  GetIncident
}

export default trackingApi
