import { AppContext } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import { useCallbackRef, useResourceLoader } from '.'
import { FetchStoresList } from '../types/FetchStoresList'
import storesApi from '../api/storesApi'
import { useContext } from 'react'
import { IDetails } from '../types/IStoreDetails'
import { ILoadStoresList } from '../types/ILoadStoresList'

const useStoresListLoader = (): [
  IResourceLoader<IDetails[]>,
  ILoadStoresList
] => {
  const context = useContext(AppContext)

  const resource = useResourceLoader<IDetails[]>('Unable to load stores list')

  const loader = useCallbackRef<FetchStoresList>(() =>
    resource.load(async () => storesApi.GetStores(context!))
  )

  return [resource, loader]
}

export default useStoresListLoader
