import { Display2 } from '@jsluna/typography'
import thumbIcon from '../../../assets/icons/positiveIcon.png'

function IncidentCounter({ count }: any) {
  if (count === 0)
    return (
      <Display2
        element="h3"
        className="a-trackingCounter ln-u-flush-bottom ln-u-text-align-center ln-o-container--xs"
        id="a-trackingCounter"
      >
        <img src={thumbIcon} alt="positiveIcon" />
        <div>There are no problems to show you here.</div>
      </Display2>
    )
  return (
    <Display2
      element="h3"
      className="a-trackingCounter ln-u-flush-bottom ln-o-container--xs"
      id="a-trackingCounter"
    >
      {count} recently updated problem
      {count === 1 ? '' : 's'} in store
    </Display2>
  )
}

export default IncidentCounter
