import { TextInputField } from '@jsluna/form'
import { useComponentContext } from '../../ComponentContext'

function InputComponent({ label, id, error, placeholder, answer }: any) {
  const { handleUpdate } = useComponentContext()
  const handleChange = (e: any) => {
    handleUpdate({ id, value: e.target.value })
  }

  return (
    <div>
      <TextInputField
        name={id}
        label={label}
        error={error}
        placeholder={placeholder}
        onChange={handleChange}
        value={answer || ''}
      />
    </div>
  )
}

export default InputComponent
