const e = encodeURIComponent

const queryParams = (params: any) =>{

// eslint-disable-next-line prefer-const
let data =  
  Object.keys(params)
    .map(k => `${e(k)}=${e(params[k])}`)
    .join('&')

return data
}

export default ( params: any ) => queryParams(params)