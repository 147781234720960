import { useComponentContext } from './ComponentContext'

// TODO throw error on unknown component
function ComponentSwitch({ type, ...props }: any) {
  const componentContext = useComponentContext()
  if (!componentContext) {
    throw new Error('Component context is not provided')
  }
  if (componentContext.availableComponents[type] !== undefined) {
    const Comp = componentContext.availableComponents[type]
    return (
      <Comp
        {...props}
        answer={(props.id && componentContext.answers[props.id]) || null}
      />
    )
  }
  return null
}

export default ComponentSwitch
