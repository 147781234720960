import { Card } from '@jsluna/card'
import { Body1, Display3 } from '@jsluna/typography'
import { IIncident } from '../../../types/IIncident'
import { FC } from 'react'
import { ReportedUpdatedOn } from '../../Card/Components'

interface IssueSummaryProps {
  incidentDetails : IIncident
}

const IssueSummary:FC<IssueSummaryProps>= ({incidentDetails})=> {
  return (
    <Card className="a-issue-summary ln-u-border" id="a-issue-summary">
      {!incidentDetails.metadata.productDescription ? (
        <h4>Product Not Found</h4>
      ) : (
        <Display3
          element="h2"
          className="a-issuesummary-issueDescription ln-u-flush"
          id="a-issue-description"
        >
          {incidentDetails.metadata.productDescription}
        </Display3>
      )}
      <Body1>
        <>
          <span
            className="a-issuesummary-issueSku ln-u-font-weight-bold"
            id="a-issueSku"
          >
            {incidentDetails.metadata.productSKU}
          </span>
          {incidentDetails.metadata.productBarcode === '' ? '' : ` / `}
          <span
            className="a-issuesummary-productBarcode ln-u-color-grey"
            id="a-productBarCode"
          >
            {incidentDetails.metadata.productBarcode}
          </span>
        </>
        <div
          className="a-issuesummary-incidentNumber ln-u-soft-bottom ln-u-color-grey"
          id="a-incident-number"
        >
          {incidentDetails.number}
        </div>
      </Body1>
      <div
        className="a-incidentIssueType ln-u-font-weight-bold"
        id="a-incident-issueType"
      >
        {incidentDetails.metadata.issueType[0].toUpperCase() +
          incidentDetails.metadata.issueType.slice(1).replace(/_/g, ' ')}
      </div>
      <div
        className="a-issuesummary-incidentShortDescription"
        id="a-incident-shortDescription"
      >
        {incidentDetails.shortDescription}
      </div>
      <div
        className="a-issuesummary-incidentDescription ln-u-soft-bottom"
        id="a-incident-description"
      >
        {incidentDetails.description}
      </div>
      <p className="ln-u-hard" id="a-issue-status">
        <span
          className="a-issuesummary-incidentState ln-u-padding ln-u-bg-color-grey-lighter ln-u-border ln-u-display-inline-block"
          id="a-incident-state"
        >
          {incidentDetails.state[0].toUpperCase() + incidentDetails.state.slice(1)}
        </span>
      </p>
      <ReportedUpdatedOn
        className="a-issuesummary-updatedOn"
        incident={incidentDetails}
      />
    </Card>
  )
}





export default IssueSummary
