import { Display3, Body1 } from '@jsluna/typography'
import { Link } from 'react-router-dom'
import { useComponentContext } from '../../ComponentContext'

function ProductInfoComponent({
  productName,
  productCategory,
  skuNumber,
  eanNumber,
}: any) {
  const componentContext = useComponentContext()

  return (
    <div id="a-logging-productInfo">
      <Display3 id="a-logging-productName" className="ln-u-flush" element="p">
        {productName}
      </Display3>
      <Body1 id="a-logging-productInfo-body" element="p">
        in&nbsp;
        <span id="a-logging-productCategory" className="ln-u-font-weight-bold">
          {productCategory}
        </span>
        <div>
          <span
            className="a-issuesummary-productSku ln-u-font-weight-bold"
            id="a-productSku"
          >
            {skuNumber}
          </span>
          {skuNumber !== '' && eanNumber !== '' ? ' / ' : ''}
          <span
            className="a-issuesummary-productBarcode ln-u-color-grey"
            id="a-productBarCode"
          >
            {eanNumber}
          </span>
        </div>
      </Body1>
      <Link
        to="/logging"
        onClick={componentContext.handleBack}
        id="a-logging-productInfo-link"
        className="ln-u-font-weight-bold"
      >
        Not the right product?
      </Link>
    </div>
  )
}

export default ProductInfoComponent
