import { Container } from '@jsluna/grid'
import { IconButton, OutlinedButton } from '@jsluna/button'
import { ArrowLeft } from '@jsluna/icons'
import { useComponentContext } from '../../ComponentContext'

function BackComponent({ text }: any) {
  const componentContext = useComponentContext()

  if (text === '') {
    return (
      <IconButton
        color="dark"
        variant="text"
        label="Back"
        onClick={componentContext.handleBack}
        id="a-back-button"
      >
        <ArrowLeft size="small" />
      </IconButton>
    )
  }

  return (
    <Container className="ln-u-push-ends">
      <OutlinedButton
        id="a-big-back-button"
        fullWidth
        onClick={componentContext.handleBack}
      >
        {text}
      </OutlinedButton>
    </Container>
  )
}

export default BackComponent
