import { OutlinedButton } from '@jsluna/button'

function LinkButtonComponent({ value, link }: any) {
  return (
    <div className="a-phone-button ln-u-push-bottom">
      <a href={link}>
        <OutlinedButton fullWidth>{value}</OutlinedButton>
      </a>
    </div>
  )
}

export default LinkButtonComponent
