import { Button } from '@jsluna/button'
import ButtonWrapper from '../ButtonWrapper'

function UrlButton({ name, url, id, variant, color }: any) {
  return (
    <ButtonWrapper>
      
        <Button
          className="a-switchboard-urlButton ln-c-button--filled ln-c-button--light"
          id={`a-switchboard-urlButton-${id}`}
          fullWidth
          variant={variant}
          color={color}
          tabIndex={-1}
          type="button" onClick={() => window.location.href = url}
        >
          {name}
        </Button>
      
    </ButtonWrapper>
  )
}

export default UrlButton
