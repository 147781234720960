import HistoryElement from './HistoryElement'
import HistoryClosed from './HistoryClosed'
import { IComments } from '../../../types/IIncident'
import { FC } from 'react'

interface IssueHistoryProps {
  issueHistory : IComments

}

const IssueHistory:FC<IssueHistoryProps>= (issueHistory)=> {

  const history = issueHistory.issueHistory

  return (
    <div>
      {history?.map((h, index) => {
        switch (h.elementType) {
          case 'closed':
            return <HistoryClosed history={h} key={index} />
          default:
            return <HistoryElement history={h} key={index} />
        }
      })}
    </div>
  )
}

export default IssueHistory
