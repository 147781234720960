import {
    AuthenticationResult,
    IPublicClientApplication,
  } from "@azure/msal-browser";
  import { IErrorResponse } from "../types/IErrorResponse";
import { getBaseUrl } from "../utils/BaseUrl";

  export class ApiClient {
    private instance: IPublicClientApplication;

    private apiScope: string;

    private baseUrl: string;

    constructor(
      instance: IPublicClientApplication,
      apiScope: string,
      baseUrl: string,
    ) {
      this.instance = instance;
      this.apiScope = apiScope;
      this.baseUrl = baseUrl;
    }

    public async getAccessToken(): Promise<string | null> {
      const accounts = this.instance.getAllAccounts();

      if (accounts.length === 0) {
        return null;
      }

      const account = accounts[0];

      try {
        const authResult: AuthenticationResult = await this.instance
          .acquireTokenSilent({
            account,
            scopes: [this.apiScope],
          });

        return authResult.accessToken;
      } catch (error) {
        console.log(error);
        return null;
      }
    }


    public async get<T>(endpoint: string): Promise<T> {
      const accessToken = await this.getAccessToken();
      const envUrl= getBaseUrl(endpoint,this.baseUrl);
      const url = new URL(endpoint, envUrl);

      if (!accessToken) {
        throw new Error("Access token not available");
      }

      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });

      if (!headers.has('Content-Type'))
      headers.append('Content-Type', 'application/json')

      const requestOptions = {
        method: "GET",
        headers,
      };

      try {
        const response = await fetch(url, requestOptions);
        
        if (response && response.ok) {
          const data= await response.json()
          const check = typeof data === "string" 
         return  check ? JSON.parse(data): data as T
       
        }
        const errorResponse = await response.json() as IErrorResponse;

        throw new Error(errorResponse.message);
      } catch (error: any) {
       
        throw new Error(error);

      }
    }

    public async post<T>(endpoint: string, body: string): Promise<T>  {
      const accessToken = await this.getAccessToken();
      const envUrl= getBaseUrl(endpoint,this.baseUrl);
      const url = new URL(endpoint, envUrl);

      if (!accessToken) {
        throw new Error("Access token not available");
      }

      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });

      if (!headers.has('Content-Type'))
      headers.append('Content-Type', 'application/json')

      const requestOptions = {
        method: "POST",
        headers,
        body: body,
      };

      try {
        const response = await fetch(url, requestOptions);
        if (response && response.ok) {
          return await response.json() as T;
        }

        const errorResponse = await response.json() as IErrorResponse;

        throw new Error(errorResponse.message);
      } catch (error: any) {
        throw new Error(error);
      }
    }
    public async uploadFiles(endpoint: string, body: FormData)  {
      const accessToken = await this.getAccessToken();
      const envUrl= getBaseUrl(endpoint,this.baseUrl);
      const url = new URL(endpoint, envUrl);

      if (!accessToken) {
        throw new Error("Access token not available");
      }

      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });
      const requestOptions = {
        method: "POST",
        headers,
        body: body,
      };

      try {
        return await fetch(url, requestOptions);
       
      } catch (error: any) {
        throw new Error(error);
      }
    }
  }