import { Container } from '@jsluna/react'
import { Display3, Display2, Display1 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import Error from '../Error'
import BackButton from '../BackButton'
import { useNavigate, useParams } from 'react-router'
import { useEntryDirectoryLoader } from '../../hooks/useDirectoryLoader'
import { useEffect } from 'react'
import Loading from '../Loading'

const SwitchboardEntry = () => {
  const { id } = useParams()
const navigate =useNavigate()
  let entry = null
  const [directoryResource, loadDirectory] = useEntryDirectoryLoader()

  useEffect(() => {
    loadDirectory.current(id!)
  }, [loadDirectory])

  if (directoryResource.hasError)
    return (
      <Error
        message="An error has occurred. "
        tryAgain={() => navigate(`/switchboard/${id}`)}
      />
    )

  if (!directoryResource.hasLoaded && !directoryResource.hasError)
    return <Loading message="Loading directory" />

  if (directoryResource.hasLoaded && directoryResource.data) {
    entry = directoryResource.data
  }

  return (
    <>
      <Container className="ln-u-push-ends">
        <BackButton previousPage="/" />
      </Container>
      <Container className="ln-u-push-ends ln-u-soft-sides">
        <Display3 element="h2">{entry?.name}</Display3>
      </Container>
      {/* <Container className="a-page-push ln-u-soft ln-u-bg-color-white"> */}
      <Container className="ln-u-push-ends">
        <Card>
          <Display2 element="h3">{entry?.descriptionTitle}</Display2>
          {entry?.description}
          <Display1
            aria-label="Incident phone number"
            element="p"
            className="a-page-push"
          >
            {entry?.phone}
          </Display1>
        </Card>
      </Container>
    </>
  )
}

export default SwitchboardEntry
