import { IIncidentState } from '../../../types/ITrackingOverview'

const IncidentState = ({ state }: IIncidentState) => {
  return (
    <p className="ln-u-hard" id="a-issue-status">
      <span
        className="a-incident-state ln-u-padding ln-u-bg-color-grey-lighter ln-u-border ln-u-display-inline-block"
        id="a-incident-state"
      >
        {state[0].toUpperCase() + state.slice(1)}
      </span>
    </p>
  )
}

export default IncidentState
