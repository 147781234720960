import { ErrorCircle, InfoCircle, Like } from '@jsluna/icons'

function IconComponent({ value }: any) {
  switch (value) {
    case 'ErrorIcon':
      return (
        <div id="a-logging-icon" className="a-red-icon ln-u-text-align-center">
          <ErrorCircle
            id="a-errorCircle"
            className="a-error-icon ln-u-color-red ln-u-display-7 ln-u-push-bottom"
          />
        </div>
      )
    case 'RedInfoIcon':
      return (
        <div
          id="a-logging-icon"
          className="a-red-info-icon ln-u-text-align-center"
        >
          <InfoCircle
            id="a-infoCicrle"
            className="a-red-info-icon ln-u-color-red ln-u-display-7 ln-u-push-bottom"
          />
        </div>
      )
    case 'LikeIcon':
      return (
        <div id="a-logging-icon" className="a-like-icon ln-u-text-align-center">
          <Like
            if="a-likeIcon"
            className="a-like-icon ln-u-color-green ln-u-display-7 ln-u-push-bottom"
          />
        </div>
      )
    default:
      return <div> </div>
  }
}

export default IconComponent
