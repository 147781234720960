import UrlButton from '../UrlButton'
import PhoneButton from '../PhoneButton'

function SwitchboardButton({ entry, color, variant }: any) {
  switch (entry && entry.type && entry.type.toLowerCase()) {
    case 'url':
      return (
        <UrlButton
          name={entry.name}
          url={entry.url}
          id={entry.id}
          color={color}
          variant={variant}
        />
      )
    case 'phone':
      return (
        <PhoneButton
          name={entry.name}
          id={entry.id}
          color={color}
          variant={variant}
        />
      )
    default:
      return null
  }
}

export default SwitchboardButton
