import { IconButton } from '@jsluna/button'
import { ArrowLeft } from '@jsluna/icons'
import { useNavigate } from 'react-router-dom'

function BackButton({ previousPage, className }: any) {
  const navigateTo = useNavigate()


  const onButtonClick = () => {
    navigateTo(previousPage)
  }

  return (
    <IconButton
      color="dark"
      variant="text"
      label="Back"
      onClick={onButtonClick}
      className={className}
      id="a-back-button"
    >
      <ArrowLeft size="small" />
    </IconButton>
  )
}

export default BackButton
