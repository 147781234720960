import { AppContextType } from '../context/AppContext'
import { IDetails, IStoreDetails } from '../types/IStoreDetails'
import { IStoresList } from '../types/IStoresList'
import webApi from './webApi'

const defaultStoreTypes = 'main,local,pfs,restaurant'

const GetStore = async (
  context: AppContextType,
  code: string
): Promise<IDetails> => {
  const storeCode = code !== '' ? code : ''

  const response = await webApi.get<IStoreDetails>(context.apiClient, {
    url: `/api/storelocator/store`,
    params: { storeCode: storeCode }
  })

  return response.store
}

const GetStores = async (
  context: AppContextType,
  query: any | undefined = '',
  lat: number | undefined = 0,
  lon: number | undefined = 0,
  storeTypeFilter: string = defaultStoreTypes
): Promise<IDetails[]> => {
  const response = await webApi.get<IStoresList>(context.apiClient, {
    url: '/api/storelocator/stores',
    params: { store_type: storeTypeFilter, lat: lat, lon: lon, complete: query }
  })

  return response.results
}

const storesApi = {
  GetStore,
  GetStores
}

export default storesApi
