import { IDirectories } from "../types/IAllEntriesDirectory"
import { IDirectory } from "../types/IEntryDirectory"
import { ApiClient } from "./ApiClient"
import webApi from "./webApi"



 const GetDirectories = async (
    apiClient: ApiClient,
    storeCode: string
  ): Promise<IDirectories[]> => {

    const response = await webApi.get<IDirectories[]>(apiClient, {
      url: `/api/directories`,
      params: { storeCode: storeCode }
    })

  return response
}
  
const GetDirectory = async (
    apiClient: ApiClient,
    identifier: string
  ): Promise<IDirectory> => {

  const response = await webApi.get<IDirectory>(apiClient, {
    url: `/api/directory`,
    params: { identifier: identifier }
  })

  return response
}



const directoryApi = {
    GetDirectories,
    GetDirectory
  }
  
  export default directoryApi