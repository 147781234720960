import { Display3 } from '@jsluna/typography'
import { Container } from '@jsluna/grid'

function TitleComponent({ value }: any) {
  if (value === 'Problem info') {
    return (
      <Container>
        <Display3 id="a-logging-page-title" element="h2">
          {value}
        </Display3>
      </Container>
    )
  }

  return (
    <Container soft>
      <Display3 id="a-logging-page-title" element="h2">
        {value}
      </Display3>
    </Container>
  )
}

export default TitleComponent
