import { Card } from '@jsluna/card'
import ComponentSwitch from '../..'

function CardComponent({ components, key }: any) {
  return (
    <Card id="a-logging-card" className="ln-u-border" soft="true">
      {components?.map((component: any) => (
        <ComponentSwitch {...component} key={`${key}-${component.id}`} />
      ))}
    </Card>
  )
}

export default CardComponent
