const page = {
  TrackingPage: null,
}

const resetData = (context: any) => {
  context!.page = page
  sessionStorage.removeItem('TrackingPage')
}

export default resetData
