import { IIssueType } from '../../../types/ITrackingOverview'

const IssueType = ({ issueType }: IIssueType) => {
  return (
    <div
      className="a-incident-issueType ln-u-font-weight-bold"
      id="a-incident-issueType"
    >
      {issueType[0].toUpperCase() +
        issueType.slice(1).replace(/_/g, ' ')}
    </div>
  )
}

export default IssueType