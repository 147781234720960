import trackingApi from "../api/trackingApi";
import IResourceLoader from "../types/IResourceLoader";
import { useApiClient } from "../context/AppContext";
import useResourceLoader from "./useResourceLoader";
import useCallbackRef from "./useCallbackRef";
import { ITracking } from "../types/ITracking";
import { FetchTracking } from "../types/FetchTracking";
import { ILoadTracking } from "../types/ILoadTracking";

function useTrackingLoader(): [IResourceLoader<ITracking>, ILoadTracking] {
  const apiClient = useApiClient()

  const resource = useResourceLoader<ITracking>('Unable to load Tracking incidents');

  const loader = useCallbackRef<FetchTracking>(async (storeCode:string, page: number): Promise<ITracking> =>
    resource.load(async () => {
      return trackingApi.GetStoreIncidents(apiClient, storeCode, page );
    }));

  return [resource, loader];
}

export default useTrackingLoader;