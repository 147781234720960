import { Card } from '@jsluna/card'
import { Body1, Caption } from '@jsluna/typography'
import getRelativeDate from '../../../../utils/relativeDate'

function HistoryClosed(history: any) {
  console.log("history", history)
  return (
    <Card
      className="a-issueHistory-comment ln-u-border-left ln-u-border-right ln-u-border-bottom"
      id={`a-issue-history-closed-${history.history.id}`}
    >
      <Body1 className="a-comment-value" id="a-comment-value">
        {history.history.value}
      </Body1>
      <br />
      <Caption
        className="a-comment-createdOnBy ln-u-color-grey"
        id="a-comment-createdOnBy"
      >
        Closed on&nbsp;
        {getRelativeDate(history.history.createdOn)}
      </Caption>
    </Card>
  )
}

export default HistoryClosed
