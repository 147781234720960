
import { GridWrapper } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { Key, useEffect } from 'react'
import { useCurrentStore } from '../../../hooks'
import { IPagination } from '../../../types/IPaginationType'
import usePage from '../../../hooks/usePage'
import useTrackingLoader from '../../../hooks/useTrackingLoader'
import { Container } from '@jsluna/grid'
import Loading from '../../Loading'
import IncidentCounter from '../IncidentCounter'
import { Pagination } from '@jsluna/pagination'
import TrackingCard from '../../Card/Cards/TrackingCard'
import { IIncidentInfo } from '../../../types/ITracking'

const TrackingList = () => {
  const storeCode = useCurrentStore().storeCode
  const pageType = 'TrackingPage'
  const {page, handlePage} = usePage(pageType)
  let trackingData: IIncidentInfo[] | undefined = []
  let pagination: IPagination | undefined

  const [trackingResource, trackingResourceLoader] = useTrackingLoader()

  useEffect(() => {
    trackingResourceLoader.current(storeCode, page)
  }, [trackingResourceLoader, storeCode, page])

  if (trackingResource.hasLoaded && !trackingResource.hasError) {
    const trackingResponse = trackingResource.data
    pagination = trackingResponse?.metadata?.pagination
    trackingData = trackingResponse?.results
  }

  if (!trackingResource.hasLoaded && !trackingResource.hasError && !trackingResource.noContent)
    return (
      <Container size="xs">
        <Card id="s-loadingElement" className="ln-u-text-align-center">
          <Loading size="large" />
        </Card>
      </Container>
    )

  if (
    trackingResource.hasError ||
    !trackingData ||
    trackingData?.length <= 0 ||
    trackingResource.noContent
  ) {
    return <IncidentCounter count={trackingData?.length ?? 0} />
  }

  return (
    <>
      <Container size="xs" className="a-page-push ln-u-soft-sides">
          <IncidentCounter
            count={
              (trackingData &&
                trackingData.length) ||
              0
            }
          />
      </Container>

      <Container className="s-reported-accidents-list" size="xs">
        <GridWrapper
          id="s-reported-accident-cards"
          matrix
          gutterSize="sm"
          className="ln-o-grid--gutter-zero@max-mobile"
        >
          {trackingData.map((incident: IIncidentInfo, index: Key) => (
            <TrackingCard key={index} incident={incident} />
          ))}
        </GridWrapper>
      </Container>

      {pagination && (
        <Pagination
          id="s-pagination-bar"
          className="ln-u-text-align-center ln-u-margin-ends*3"
          showFirstAndLast
          current={pagination.currentPage + 1}
          total={pagination.totalPages}
          onChange={(page: number, e: any) => {
            e.preventDefault()
            handlePage(pageType, page - 1)
      }}
        />
      )}
    </>
  )
}

export default TrackingList
