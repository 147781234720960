import trackingApi from "../api/trackingApi";
import IResourceLoader from "../types/IResourceLoader";
import { useApiClient } from "../context/AppContext";
import useResourceLoader from "./useResourceLoader";
import useCallbackRef from "./useCallbackRef";
import { IIncident } from "../types/IIncident";
import { FetchIncident } from "../types/FetchIncident";
import { ILoadIncident } from "../types/ILoadIncident";

function useIncidentLoader(): [IResourceLoader<IIncident>, ILoadIncident] {
  const apiClient = useApiClient();

  const resource = useResourceLoader<IIncident>('Unable to load incident');

  const loader = useCallbackRef<FetchIncident>(async (incidentIdentifier: string): Promise<IIncident> =>
    resource.load(async () => trackingApi.GetIncident(apiClient, incidentIdentifier)));

  return [resource, loader];
}

export default useIncidentLoader;