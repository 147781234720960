import { AppContext } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import { useCallbackRef, useResourceLoader } from '.'
import { ILoadStore } from '../types/ILoadStore'
import { FetchStore } from '../types/FetchStore'
import storesApi from '../api/storesApi'
import { IDetails } from '../types/IStoreDetails'
import { useContext } from 'react'

const useStoreLoader = (): [IResourceLoader<IDetails>, ILoadStore] => {
  const context = useContext(AppContext)

  const resource = useResourceLoader<IDetails>('Unable to load store')

  const loader = useCallbackRef<FetchStore>((code: string) =>
    resource.load(async () => storesApi.GetStore(context!, code))
  )

  return [resource, loader]
}

export default useStoreLoader
