import { TextButton } from '@jsluna/button'
import { useComponentContext } from '../../ComponentContext'

function CancelComponent({ text }: any) {
  const componentContext = useComponentContext()
  return (
    <TextButton
      id="a-logging-cancel-button"
      fullWidth
      color="dark"
      onClick={componentContext.handleCancel}
    >
      {text ? text : 'Cancel'}
    </TextButton>
  )
}

export default CancelComponent
