import { GridItem } from '@jsluna/grid'
import { Cancel } from '@jsluna/icons'

function ImagePreview({ images, removeImage }: any) {
  if (!images || images.length <= 0) {
    return null
  }

  return Object.entries(images).map(([key, image]: any) => (
    <GridItem key={key} size={{ xs: '1/2' }}>
      <div className="a-image-container">
        <p key={key}>{image.displayName}</p>
        <button
          type="button"
          className="a-remove-image-button ln-u-margin-left icon-top-left"
          onClick={() => removeImage(image)}
        >
          <Cancel size="Large" fixed />
        </button>
        <img
          src={image.previewUrl}
          className="a-uploaded-image"
          alt="issueImage"
        />
      </div>
    </GridItem>
  ))
}

export default ImagePreview
