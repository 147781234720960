import { Link, NavLink, useLocation } from 'react-router-dom'
import { Display3, Caption } from '@jsluna/typography'
import {
  Header as LunaHeader,
  HeaderLogo,
  HeaderNav,
  HeaderMainBar,
  HeaderGlobalItem
} from '@jsluna/header'
import HeaderGlobalBar from './CustomHeaderGlobalBar'
import {
  ChangeStore,
  NavCurrentUser,
  NavStoreName,
  SainsburysLogo
} from './nav'
import SignOutButton from '../auth/SignOutButton'
import { FC } from 'react'
import { IStore } from '../../types/IStore'
import AssistViewFilter from '../AssistViewFilter'

interface NavHeaderProps {
  currentStore: IStore
}

const NavHeader: FC<NavHeaderProps> = ({ currentStore }) => {
  const { pathname } = useLocation()

  return (
    <LunaHeader divided hasGlobalBar hasMainBar>
      <HeaderGlobalBar
        label="Group navigation"
        element="nav"
        className={undefined}
        size={undefined}
      >
        <HeaderGlobalItem>
          <SainsburysLogo />
        </HeaderGlobalItem>
        <HeaderGlobalItem align="right" drawer="true" tabbar="md">
          <span id="assist-user-store">
            <NavCurrentUser />&nbsp;in&nbsp;<NavStoreName currentStore={currentStore} />
          </span>
        </HeaderGlobalItem>
        <HeaderGlobalItem>
          <AssistViewFilter>
          <span id="assist-nav-link-stores">
            <ChangeStore />
          </span>
          </AssistViewFilter>
        </HeaderGlobalItem>
        <HeaderGlobalItem>
          <span id="assist-nav-link-signOut">
            <SignOutButton />
          </span>
        </HeaderGlobalItem>
        
      </HeaderGlobalBar>

      <HeaderMainBar size="nav">
        <HeaderLogo>
          <Display3 id="assist-logo">
            <NavLink to="/" style={{ textDecoration: 'none' }}>
              Assist
            </NavLink>
          </Display3>
        </HeaderLogo>
        <HeaderNav openDropdownOnHover drawer tabBar="md" label="Main">
          <AssistViewFilter active={pathname === '/tracking'}>
            <Link to="/tracking" id="a-nav-link-tracking">
              Tracking
            </Link>
          </AssistViewFilter>
          <AssistViewFilter active={pathname === '/logging'}>
            <Link to="/logging" id="a-nav-link-logging">
              Report a problem
            </Link>
          </AssistViewFilter>
        </HeaderNav>
        <HeaderNav align="right" drawer className="a-u-hidden@max-nav">        
        <Caption> <NavCurrentUser />&nbsp;in&nbsp;<NavStoreName currentStore={currentStore} />  </Caption>
          <AssistViewFilter>
          <Caption>
            {' '}
            <ChangeStore />{' '}
          </Caption>
          </AssistViewFilter>
          <Caption>
            {' '}
            <SignOutButton />
          </Caption>
        </HeaderNav>
      </HeaderMainBar>
    </LunaHeader>
  )
}

export default NavHeader
