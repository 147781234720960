import loggingApi from "../api/loggingApi";
import { useReducer } from "react";


const SET_STORE_CODE = 'set_store_code'
const UPDATE_ANSWER = 'update_answer'
const SET_LOADING = 'set_loading'
const UPDATE_PAGE = 'update_page'
const NEXT = 'next'
const BACK = 'back'
const CANCEL = 'cancel'
const ERROR = 'error'

export const initialState = {
  loading: true,
  answers: {},
  submitted: false,
  error: false,
}

export const setStore = (storeCode: any) => ({ type: SET_STORE_CODE, storeCode })

export const updateAnswer = (componentId: any, answer: any) => ({
  type: UPDATE_ANSWER,
  componentId,
  answer,
})

export const setLoading = () => ({
  type: SET_LOADING,
})

export const updatePage = (payload: any) => ({
  type: UPDATE_PAGE,
  payload,
})

export const error = (message: string, exception: unknown) => ({
  type: ERROR,
  message,
  exception,
})

export const next = ({ id, value } :any) => ({
  type: NEXT,
  overrides: (id && { id, value }) || null,
})

export const back = () => ({ type: BACK })
export const cancel = () => ({ type: CANCEL })

export function reducer(state:any, action:any) {
  switch (action.type) {
    case SET_STORE_CODE:
      return { ...state, storeCode: action.storeCode }
    case SET_LOADING:
      return { ...state, loading: true }
    case UPDATE_PAGE: {
      const newState = {
        ...state,
        serviceType: action.payload?.serviceType,
        id: action.payload?.id,
        sessionId: action.payload?.sessionId,
        components: action.payload?.components,
        loading: false,
        submitted: action.payload?.submitted === true,
      }

      if (
        state.serviceType !== newState.serviceType ||
        state.id !== newState.id
      )
        newState.answers = {}

      newState.answers = {
        ...(newState?.answers || {}),
        ...(action.payload?.answerOverride || {}),
      }

      return newState
    }
    case UPDATE_ANSWER: {
      return {
        ...state,
        answers: { ...state.answers, [action.componentId]: action.answer },
      }
    }
    case ERROR:
      return {
        ...state,
        error: { exception: action.exception, message: action.message },
        loading: false,
      }
    default:
      return state
  }
}

export const asyncDispatch = (dispatcher:any, state:any,apiClient:any) => async (action: any) => {
 
  switch (action.type) {
    case SET_STORE_CODE: {
      dispatcher(action)
      try {
        const result = await loggingApi.FormCreate(apiClient, { storeCode: action.storeCode })
        dispatcher(updatePage(result))
      } catch (e) {
        dispatcher(error('Failed to load questions', e))
      }
      break
    }
    case NEXT: {
      try {
        dispatcher(setLoading())
        const payload = {
          sessionId: state.sessionId,
          storeCode: state.storeCode,
          answers: state.answers,
        }

        if (action.overrides) {
          payload.answers = {
            ...payload.answers,
            [action.overrides.id]: action.overrides.value,
          }
        }

        const result = await loggingApi.FormCreate(apiClient, payload)

        dispatcher(updatePage(result))
      } catch (e) {
        dispatcher(error('Failed to submit answers', e))
      }
      break
    }
    case BACK: {
      try {
        dispatcher(setLoading())
        const result = await loggingApi.FormCreate(apiClient,{
          sessionId: state.sessionId,
          storeCode: state.storeCode,
          answer: state.answers,
          action: 'back',
        })

        dispatcher(updatePage(result))
      } catch (e) {
        dispatcher(error('Failed to go back', e))
      }
      break
    }
    case CANCEL: {
      try {
        dispatcher(setLoading())
        const result = await loggingApi.FormCreate(apiClient,{
          storeCode: state.storeCode,
        })

        dispatcher(updatePage(result))
      } catch (e) {
        dispatcher(error('Failed to load questions', e))
      }
      break
    }
    default:
      dispatcher(action)
      break
  }
}

const useLoggingReducer = (apiClient:any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return [state, asyncDispatch(dispatch, state,apiClient)]
}

export default useLoggingReducer

