import getRelativeDate from '../../../utils/relativeDate'

function ReportedUpdatedOn( incident: any) {
  if (incident.incident.updatedOn)
    return (
      <div
        className="ln-u-push ln-u-color-grey  ln-u-flush"
        id="a-incident-updatedOn"
      >
        {incident.incident.updatedBy.displayName} updated on&nbsp;
        {getRelativeDate(incident.incident.updatedOn)}
      </div>
    )

  return (
    <div
      className="ln-u-push ln-u-color-grey  ln-u-flush"
      id="a-incident-reportedOn"
    >
      {incident.incident.createdBy.displayName} reported on&nbsp;
      {getRelativeDate(incident.incident.createdOn)}
    </div>
  )
}

export default ReportedUpdatedOn
