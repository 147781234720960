import { Card } from '@jsluna/card'
import { Caption } from '@jsluna/typography'
import getRelativeDate from '../../../utils/relativeDate'

function Caller(callerDetails: any) {
  const callerName = callerDetails.callerDetails?.callerId?.displayName
  return (
    <Card
      className="a-issueHistory-comment ln-u-border-left ln-u-border-right ln-u-border-bottom"
      id={`a-issue-caller-${callerDetails.id}`}
    >
      <Caption id="a-caller-createdOnBy">
        <span className="ln-color-grey-dark ln-u-font-weight-bold">
          {callerName && `${callerName}\u00A0`}
        </span>
      </Caption>

      <Caption className="ln-u-color-grey">
        Reported on&nbsp;
        {getRelativeDate(callerDetails.callerDetails?.createdOn)}
      </Caption>
    </Card>
  )
}
export default Caller
