import { FilledButton } from '@jsluna/button'
import { Container } from '@jsluna/grid'
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage,
} from '@jsluna/progress'
import { useComponentContext } from '../../ComponentContext'

function NextComponent({ text }: any) {
  const message = text !== '' ? text : 'Next'
  const componentContext = useComponentContext()
  return (
    <Container className="ln-u-push-ends">
      <FilledButton
        id="a-next-button"
        fullWidth
        onClick={componentContext.handleNext}
        disabled={componentContext.isNextDisabled()}
      >
        {componentContext.isNextDisabled() && (
          <ProgressIndicator
            loading
            preventFocus
            message={
              <ProgressMessage style={{ position: 'absolute', right: '46%' }}>
                {message}
              </ProgressMessage>
            }
          >
            <ProgressSpinner
              color="light"
              size="small"
              className="ln-u-margin-right"
            />
          </ProgressIndicator>
        )}
        {!componentContext.isNextDisabled() && message}
      </FilledButton>
    </Container>
  )
}

export default NextComponent
