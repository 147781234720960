import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { IDetails } from "../types/IStoreDetails";


const useCurrentStoresList = (): IDetails[] => {
  const context = useContext(AppContext)

  const [storesList,setStoresList]=useState(context && context.storeList !== undefined ? context.storeList : [])

  useEffect(() => {
    if (context  && context.storeList !== undefined) {
      setStoresList(context.storeList)
     
    }
  }, [context!.storeList]);

return storesList
}

export default useCurrentStoresList;