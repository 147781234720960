import { Link } from 'react-router-dom'
import { Button } from '@jsluna/button'
import ButtonWrapper from '../ButtonWrapper'

function PhoneButton({ id, name, color, variant }: any) {
  return (
    <ButtonWrapper>
      <Link to={`/switchboard/${id}`}>
        <Button
          className="a-switchboard-phoneButton ln-c-button--filled ln-c-button--light"
          id={`a-switchboard-phoneButton-${id}`}
          fullWidth
          color={color}
          variant={variant}
        >
          {name}
        </Button>
      </Link>
    </ButtonWrapper>
  )
}

export default PhoneButton
