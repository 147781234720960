import { useContext, useEffect, useState } from 'react'
import useStoresListLoader from '../hooks/useStoresListLoader'
import { AppContext } from '../context/AppContext'
import { useNavigate } from 'react-router'
import { useSearchStoresListLoader } from '../hooks'
import { Card, Container } from '@jsluna/react'
import StoreSearch from '../components/StorePage/StoreSearch/StoreSearch'
import StoreList from '../components/StorePage/StoreList/StoreList'
import Loading from '../components/Loading'
import resetData from '../utils/resetData'

const StorePage = () => {
  const navigateTo = useNavigate()
  const context = useContext(AppContext)

  const [searchQuery, setSearchQuery] = useState({ query: '', code: '' })
  const [storesListResource, loadStores] = useStoresListLoader()

  const [searchStoresListResource, loadSearchStores] =
    useSearchStoresListLoader()

  const setSearchStoreList = (code: string, query: string) => {
    if (query !== '' || code !== '') {
      loadSearchStores.current(code, query)
      if (context && searchStoresListResource.isReady) {
        context.storeList = searchStoresListResource.data
      }
    }
  }

  useEffect(() => {
    loadStores.current()
  }, [loadStores])

  useEffect(() => {
    setSearchStoreList(searchQuery.code, searchQuery.query)
  }, [searchQuery.query, searchQuery.code])

  const changeCurrentStore = (storeSelected: any) => {
    const newStore = {
      storeCode: storeSelected.code,
      storeName: storeSelected.name,
      inScope: storeSelected ? true : false
    }
    if (context && newStore) {
      context.store = newStore
      resetData(context)
      navigateTo('/tracking')
    }
  }

  const searchStores = (code: string, query: string) => {
    setSearchQuery({ code: code, query: query })
    setSearchStoreList(code, query)
  }

  if (searchQuery.code === '' && searchQuery.query === '') {
    if (context && storesListResource.data) {
      context.storeList = storesListResource.data
    }
  } else {
    if (
      context &&
      searchStoresListResource.isReady &&
      !storesListResource.hasError
    ) {
      context.storeList = searchStoresListResource.data
    }
  }

  if (
    searchQuery.code === '' &&
    searchQuery.query === '' &&
    !storesListResource.hasLoaded &&
    !storesListResource.hasError
  )
    return <Loading message={'Loading Stores'} />

  return (
    <Container size="md" soft>
    <div className="a-store-page a-store-mobile">
      <Card padded="true" id="a-store-search-page-card" className="a-page-card">
        <StoreSearch searchStores={searchStores} />
        <StoreList
          selectStore={changeCurrentStore}
          queryCode={searchQuery.code}
        />
      </Card>
    </div>
    </Container>
  )
}

export default StorePage
