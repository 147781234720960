import classnames from 'classnames'

import { Container } from '@jsluna/grid'

function GlobalBar({ element, children, className, label, size, ...rest }: any) {
  const Element = element

  return (
    <Element
      className={classnames('ln-c-header__global-bar', className)}
      aria-label={label}
      {...rest}
    >
      <Container className="ln-c-header__global-bar-inner" size={size}>
        {children}
      </Container>
    </Element>
  )
}

GlobalBar.displayName = 'HeaderGlobalBar'

export default GlobalBar
