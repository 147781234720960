import { ApiClient } from './ApiClient'
import webApi from './webApi'
import { ILogging } from '../types/ILogging'

export const FormCreate = async (
        apiClient: ApiClient,
        response: any
      ): Promise<ILogging> => {
       return await webApi.post<ILogging>(apiClient, {
          url: `/api/form/create`,
          params: [],
          body: JSON.stringify(response)
        })
      }


export const FileUpload = async (apiClient: ApiClient, { ...files } = {}) => {
  let totalSize = 0
  const fd = new FormData()
  Object.entries(files).map(([, value]:[any,any]) => {
    totalSize += value.size
    fd.append(value.name, value)
  })
  if (totalSize > 20971520) {
    return {
      error: true,
      message: 'Combined file size exceeds 20MB, please upload smaller file(s)',
    }
  }

  const response =  await webApi.uploadFiles(apiClient,  'api/logging/images',fd);

  if (response.status === 200) {
    return {
      error: false,
      imageNames: await response.json(),
    }
  }

  return {
    error: true,
    message:
      'Failed to upload file(s), please check that they are all valid image types and total less than 20MB',
  }
}


const loggingApi = {
    FormCreate,
    FileUpload
  }
  
  export default loggingApi

















