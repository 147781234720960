import React from 'react'
import PropTypes from 'prop-types'
import Error from '../../Error'

class LoggingErrorBoundary extends React.Component {
  static propTypes: { children: any }
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { hasError }: any = this.state
    const { children }: any = this.props
    if (hasError) {
      return <Error message="An error has occurred." />
    }
    return children
  }
}

LoggingErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LoggingErrorBoundary
